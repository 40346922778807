<template>
  <div>
    <b-sidebar
      id="sidebar-cate-handler"
      sidebar-class="sidebar-lg"
      :visible="isCategoryHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-category-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5 class="mb-0"> {{ categoryLocal.id ? '修改目录' : '添加目录' }} </h5>
          <div>
            <feather-icon
              v-show="categoryLocal.id" icon="TrashIcon" class="cursor-pointer"
              @click="$emit('remove-category'); hide();"
            />
            <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- 目录名称 -->
            <validation-provider
              #default="validationContext"
              name="目录名称"
              rules="required"
            >
              <b-form-group
                label="目录名称"
                label-for="category-title"
              >
                <b-form-input
                  id="category-title"
                  v-model="categoryLocal.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="目录名称"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- pid -->
            <b-form-group label="父级目录" label-for="pid">
              <validation-provider name="父级目录" #default="{ errors }">
                <v-select
                  v-model="categoryLocal.pid"
                  :state="errors.length > 0 ? false:null"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="categoryList" :clearable="false" label="html"
                  :reduce="val => val.id"
                  input-id="pid"
                >
                  <template v-slot:no-options>没有找到相关信息</template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2 mx-2 justify-content-between">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                重置
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ categoryLocal.id ? '编辑' : '新增' }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import useCategoryHandler from './useCategoryHandler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,

    // 3rd party packages
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isCategoryHandlerSidebarActive',
    event: 'update:is-category-handler-sidebar-active',
  },
  props: {
    isCategoryHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    category: {
      type: Object,
      required: true,
    },
    categoryList: {
      type: Array,
      required: true,
    },
    clearCategoryData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const {
      categoryLocal,
      resetCategoryLocal,

      onSubmit,
    } = useCategoryHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetCategoryLocal, props.clearCategoryData)

    return {
      // Add New
      categoryLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
  padding-left: 0;
  }
}
</style>
