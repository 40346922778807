<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content file-sidebar">
        <div class="file-app-menu">
          <div class="add-file">
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              text="附件管理"
              variant="primary"
              block
            >
              <b-dropdown-item v-for="type in uploadLabel" :key="type.value" @click="menuClick(type)">
                <feather-icon :icon="type.icon" />
                <span class="align-middle ml-50">{{ type.title }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <vue-perfect-scrollbar
            :settings="perfectScrollbarSettings"
            class="sidebar-menu-list scroll-area"
          >
            <!-- 目录 -->
            <div class="mt-1 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1"> 存储目录 </h6>
              <!-- <feather-icon icon="PlusIcon" /> -->
            </div>

            <div class="d-flex align-items-center cursor-pointer mb-1 px-2">
              <Tree :data="fileCategory"
                :render="renderContent"
                @on-select-change="nodeClick"
              >
              </Tree>
            </div>

            <!-- 文件类型筛选 -->
            <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1"> 文件类型 </h6>
              <!-- <feather-icon icon="PlusIcon" /> -->
            </div>

            <!-- Filters -->
            <b-list-group class="list-group-filters">
              <b-list-group-item
                class="px-3"
                v-for="label in fileLabel"
                :key="label.title"
                :active="label.value == fileType"
                @click="typeClick(label)"
              >
                <feather-icon :icon="label.icon" size="18" class="mr-75" />
                <span class="align-text-bottom line-height-1">{{ label.title }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- 标签 -->
            <!-- <div class="mt-3 px-2 d-flex justify-content-between">
              <h6 class="section-label mb-1"> 文档标签 </h6>
            </div>

            <b-list-group class="list-group-labels">
              <b-list-group-item
                class="px-3"
                v-for="tag in tags"
                :key="tag.title + $route.path"
              >
                <span
                  class="bullet bullet-sm mr-1"
                  :class="`bullet-${tag.color}`"
                />
                <span>{{ tag.title }}</span>
              </b-list-group-item>
            </b-list-group> -->

          </vue-perfect-scrollbar>
        </div>
      </div>
    </div>

    <vue-context ref="menu">
      <li>
        <b-link
          v-for="item in menuDatalist"
          :key="item.text"
          class="d-flex align-items-center"
          @click="menuRightClick(item.value)"
        >
          <feather-icon
            :icon="item.icon"
            size="16"
          />
          <span class="ml-75">{{ item.text }}</span>
        </b-link>
      </li>
    </vue-context>
</div>
</template>

<script>
import { ref } from '@vue/composition-api'
import VueContext from 'vue-context'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BButton, BListGroup, BListGroupItem } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    VueContext,
  },
  props: {
    // tags: {
    //   type: Array,
    //   required: true,
    // },
    fileCategory: {
      type: Array,
      required: true,
    },
    fileType: {
      type: String,
      required: true,
    },
    uploadLabel: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const perfectScrollbarSettings = { maxScrollbarLength: 60 }
    // image图片、doc文档、audio音频、video视频
    const fileLabel = [
      { title: '全部类型', icon: 'FileMinusIcon', value: '' },
      { title: '文档附件', icon: 'FileTextIcon', value: 'doc' },
      { title: '图形图像', icon: 'ImageIcon', value: 'image' },
      { title: '视频文件', icon: 'VideoIcon', value: 'video' },
      { title: '音乐音频', icon: 'MusicIcon', value: 'audio' },
    ]

    const menu = ref(null)

    const menuData = [
      { icon: 'PlusIcon', text: '添加', value: 'create', type: 0 },
      { icon: 'FileIcon', text: '修改', value: 'edit', type: 1 },
      { icon: 'XIcon', text: '删除', value: 'delete', type: 1 },
    ]
    const menuDatalist = ref([])

    const getMenu = () => {
      menuDatalist.value = JSON.parse(JSON.stringify(menuData))
    }

    // 目录点击 处理 文件子目录及更改单前目录ID
    const nodeClick = (treeNode, node) => {
      emit('category-array-change', node.id, node.children)
    }

    // 存储目录插入文件夹 使用iview 的tree 控件 使用render函数修改
    const renderContent = (h, { data }) => {
      return h('span', {
        style: { display: 'inline-block', width: '100%', padding: '0.25rem' },
        on: {
          contextmenu: e => {
            // 关闭浏览器右键
            e.preventDefault()
            // 处理根目录只能添加子目录，不能修改编辑
            getMenu()
            // 打开自定义右键菜单
            menu.value.open(e, data)
          }
        },
      },
      [
        h('span', [
          h('feather-icon', {
            props: { icon: 'FolderIcon' },
            class: 'align-text-bottom line-height-1',
            style: { marginRight: '8px', width: '18px', height: '18px' }
          }),
          h('span', data.name)
        ])
      ])
    }

    const menuClick = type => {
      if (type.type === 2) {
        emit('create-category', 0)
      } else {
        emit('up-load-handler', type.value)
      }
      emit('close-left-sidebar')
    }

    const typeClick = label => {
      emit('close-left-sidebar')
      emit('filter-type', label.value)
    }

    const menuRightClick = value => {
      if (value === 'create') emit('create-category', menu.value.data.id)
      if (value === 'edit') emit('edit-category', menu.value.data)
      if (value === 'delete') emit('delete-category', menu.value.data.id)
    }

    return {
      perfectScrollbarSettings,

      renderContent,
      fileLabel,

      menuDatalist,
      menu,

      nodeClick,
      menuClick,
      typeClick,
      menuRightClick,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-context.scss';
.ivu-tree-arrow {
  margin: 0.35rem 0;
  padding: 0.25rem 0;
}
.ivu-tree-title {
  margin: 0.35rem 0;
  }
</style>
