<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{'show': mqShallShowLeftSidebar}"
      @click="mqShallShowLeftSidebar = false"
    />
    <div class="file-app-list">

      <!-- App Searchbar Header -->
      <div class="app-fixed-search d-flex align-items-center">

        <!-- Toggler -->
        <div class="sidebar-toggle d-block d-lg-none ml-1">
          <feather-icon icon="MenuIcon" size="21" class="cursor-pointer" @click="mqShallShowLeftSidebar = true" />
        </div>

        <!-- Searchbar -->
        <div class="d-flex align-content-center justify-content-between w-100">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" class="text-muted" />
            </b-input-group-prepend>
            <b-form-input v-model="searchParams.name" placeholder="文件名称" />
          </b-input-group>
        </div>

        <!-- 批量操作 -->
        <div class="d-flex align-items-center mr-1">

          <feather-icon @click="createCategoryClick" icon="FolderPlusIcon" size="17" class="cursor-pointer mr-1"/>

          <feather-icon icon="CheckSquareIcon" @click="selectAllClick" size="17" class="cursor-pointer mr-1"/>

          <feather-icon icon="RotateCwIcon" @click="cancelAllClick" size="17" class="text-body cursor-pointer mr-1"/>

          <b-dropdown variant="link" no-caret toggle-class="p-0" right>
            <template #button-content>
              <feather-icon icon="UploadIcon" size="17" class="align-middle text-body"/>
            </template>
            <b-dropdown-item v-for="item in uploadLabel.filter(it => it.type == 1)" :key="item.value"
              @click="upLoadClick(item.value)">
              <feather-icon :icon="item.icon" />
              <span class="align-middle ml-50">{{ item.name }}</span>
            </b-dropdown-item>
          </b-dropdown>

          <Upload ref="refInputUpload"
            :headers="header"
            :action="fileUrl"
            :data="uploadData"
            :show-upload-list="false"
            :on-success="handleSuccess"
            :format="formatFile"
            :accept="acceptFile"
            :max-size="maxSize"
            :on-format-error="handleFormatError"
            :on-exceeded-size="handleMaxSize"
            :before-upload="handleBeforeUpload"
            :multiple="isMultiple"
            :name="isMultiple ? 'uploadFiles' :'uploadFile'"
            class="d-none" />

          <!-- dowload -->
          <!-- <feather-icon icon="MoveIcon" @click="moveFileClick" size="17" class="cursor-pointer ml-1"/> -->

          <feather-icon @click="deleteFileClick" icon="TrashIcon" size="17" class="cursor-pointer ml-1"/>
        </div>

        <div class="d-flex align-items-center">
            <b-button-group>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary" size="sm" class="btn-icon"
              >
                <feather-icon icon="GridIcon" />
              </b-button>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-primary" size="sm" class="btn-icon"
              >
                <feather-icon icon="ListIcon" />
              </b-button>
            </b-button-group>
        </div>
      </div>

      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="file-manage-list-wrapper scroll-area py-75 px-1"
      >
        <section class="grid-view">
          <div
            v-for="file in fileArray"
            :key="file.id"
            class="file-card"
            :class="selected.includes(file.id) ? 'selected':''"
            @contextmenu.prevent="event => fileMenuOpen(event, file)"
          >
            <b-form-checkbox-group id="checkbox-group" v-model="selected">
              <div class="item-check"> <b-form-checkbox :value="file.id"></b-form-checkbox> </div>
              <div class="item-img text-center">
                  <b-img
                    :alt="`${file.name}-${file.id}`"
                    fluid
                    class="card-img-top"
                    :src="file.path"
                    @error="(ev) => onHandleImgUrlError(ev, file)"
                  />
              </div>
              <div class="item-name text-center text-truncate font-weight-bolder"> {{ file.name }}</div>
              <div class="item-description text-center"> {{ fileSize(file.size) }} </div>

              <!-- <div class="item-active">
                <feather-icon icon="MoreVerticalIcon" size="18" class="cursor-pointer" />
              </div> -->
            </b-form-checkbox-group>
          </div>
        </section>
        <div
          class="no-results"
          :class="{'show': !fileArray.length}"
        >
          <h5>没有查找到文件</h5>
        </div>
      </vue-perfect-scrollbar>

      <div class="d-flex justify-content-between align-items-center px-2" style="height: 3rem">
        <span class="text-nowrap"> 共 {{ total }} 条记录 </span>
        <b-pagination v-model="searchParams.page" :total-rows="total" :per-page="searchParams.size" first-number last-number align="right" prev-class="prev-item" next-class="next-item" class="mb-0">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>

    <!-- File Category -->
    <file-category-handler-sidebar
      v-model="isCategoryHandlerSidebarActive"
      :category="category"
      :category-list="categoryList"
      :clear-category-data="clearCategoryData"
      @remove-category="removeCategory"
      @update-category="updateCategory"
    />

    <!-- Sidebar -->
    <!-- :tags="tags" -->
    <portal to="content-renderer-sidebar-left">
      <file-left-sidebar
        :class="{'show': mqShallShowLeftSidebar}"
        :upload-label="uploadLabel"
        :file-category="fileCategory"
        :file-type="searchParams.type"
        :is-category-handler-sidebar-active.sync="isCategoryHandlerSidebarActive"
        @category-array-change="categoryArrayChange"
        @create-category="createCategoryHandler"
        @edit-category="updateCategoryHandler"
        @delete-category="deleteCategoryHandler"
        @filter-type="filterTypeChange"
        @close-left-sidebar="mqShallShowLeftSidebar = false"
        @up-load-handler="upLoadClick"
      />
    </portal>

    <!-- 文件右键菜单 -->
    <vue-context ref="fileMenu">
      <li>
        <b-link
          v-for="data in fileMenuList"
          :key="data.text"
          class="d-flex align-items-center"
          @click="menuFileRightClick(data.value)"
        >
          <feather-icon
            :icon="data.icon"
            size="16"
          />
          <span class="ml-75">{{ data.text }}</span>
        </b-link>
      </li>
    </vue-context>

    <file-change-category-modal
      v-model="shallFileChangeCategoryModal"
      :category-list="categoryList"
      @file-move-category="fileMoveCategory"
    />

    <file-reset-name-modal
      v-model="shallFileResetNameModal"
      @file-reset-name="fileResetName"
    />
  </div>
</template>

<script>
// import store from '@/store'
import {
  ref, watch, computed, onMounted, reactive, nextTick
  // onUnmounted
} from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import VueContext from 'vue-context'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  fileDeleteApi,
  fileListApi,
  fileEditCateApi,
  fileEditNameApi,
} from '@/api/system/files'
import {
  fileCategoryTreeApi,
  fileCategorySaveApi,
  fileCategorySelectApi,
  fileCategoryDeleteApi,
} from '@/api/system/filesCate'
import { useRouter } from '@core/utils/utils'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'

import FileChangeCategoryModal from '@/comps/FileChangeCategoryModal'
import FileResetNameModal from '@/comps/FileResetNameModal'
import { distinctArrToPropArr } from '@/libs/array'

import FileLeftSidebar from './FileLeftSidebar.vue'
import FileCategoryHandlerSidebar from './FileCategoryHandlerSidebar.vue'
import fileUpload from './fileUpload'
import fileFormatType from './fileType'

export default {
  directives: {
    Ripple,
  },
  components: {
    VuePerfectScrollbar,
    VueContext,

    // App SFC
    FileLeftSidebar,
    FileCategoryHandlerSidebar,

    FileChangeCategoryModal,
    FileResetNameModal,
  },
  setup(props, context) {
    /* 必要组件加载区域 */

    // 权限组件 弹出确认组件
    const { $swal, $copyText } = context.root
    // 消息提醒组件
    const toast = useToast()

    // const { route, router } = useRouter()
    const { route } = useRouter()
    // 路由参数，用于定位筛选（可以使用缓存，后台配置一个默认，前端对应存入缓存
    const routeParams = computed(() => route.value.params)

    const perfectScrollbarSettings = { maxScrollbarLength: 150 }
    // 编辑文件目录的对话框控制
    const isCategoryHandlerSidebarActive = ref(false)

    const tags = [
      { title: '严重', color: 'danger' },
      { title: '重要', color: 'warning' },
      { title: '适度', color: 'primary' },
      { title: '一般', color: 'success' },
      { title: '普通', color: 'info' },
    ]
    // UI 呈现数据
    const uploadLabel = [
      { title: '新建目录', name: '目录', icon: 'FileTextIcon', value: 'category', type: 2 },
      { title: '上传文件', name: '文件', icon: 'FileTextIcon', value: '', type: 1 },
      { title: '上传文档', name: '文档', icon: 'FileTextIcon', value: 'doc', type: 1 },
      { title: '上传图像', name: '图像', icon: 'ImageIcon', value: 'image', type: 1 },
      { title: '上传视频', name: '视频', icon: 'VideoIcon', value: 'video', type: 1 },
      { title: '上传音乐', name: '音乐', icon: 'MusicIcon', value: 'audio', type: 1 },
    ]

    const fileMenuList = [
      // { icon: 'EyeIcon', text: '查看', value: 'view', type: 1 },
      { icon: 'MoveIcon', text: '移动', value: 'move', type: 1 },
      { icon: 'EditIcon', text: '修改', value: 'edit', type: 1 },
      { icon: 'XIcon', text: '删除', value: 'delete', type: 1 },
      { icon: 'CopyIcon', text: '地址', value: 'copy', type: 1 },
      // { icon: 'DownloadIcon', text: '下载', value: 'down', type: 1 },
    ]

    // 基础信息

    // 文件列表
    const fileArray = ref([])
    // 计算文件大小
    const fileSize = size => {
      if (size >= 1024 * 1024 * 1024) {
        return (size / (1024 * 1024 * 1024)).toFixed(2) + ' GB'
      } else if (size >= 1024 * 1024) {
        return (size / (1024 * 1024)).toFixed(2) + ' MB'
      } else if (size >= 1024) {
        return (size / 1024).toFixed(2) + ' KB'
      } else return '1 KB'
    }
    // 计算文件大小
    const fileTypeName = name => {
      const arr = name.split('.')
      if (fileFormatType.includes(arr[arr.length - 1] + '.svg')) return arr[arr.length - 1] + '.svg'
      return 'weizhiwenjian.svg'
    }
    // 文件数量
    const total = ref(null)
    // 选中的文件
    const selected = ref([])
    // 查询条件
    const searchParams = reactive({ page: 1, size: 21, type: '', category_id: 0, name: '', tag: '' })

    // 目录tree
    const fileCategory = ref([])

    // 左侧目录筛选条件 控制（较小情况下隐藏）
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()

    // 目录获取 同时设置 子目录文件夹对象
    const getFileCategory = () => {
      fileCategoryTreeApi()
        .then(res => {
          fileCategory.value = res.data
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取文件目录失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }
    // 页面渲染 加载文件目录
    onMounted(() => { getFileCategory() })
    // 获取文件列表
    const getFileList = () => {
      fileListApi(searchParams)
        .then(res => {
          fileArray.value = res.data.list
          total.value = res.data.total
          selected.value = []
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取文件列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }
    // 查询条件变化时，触发查询
    watch(searchParams, () => { getFileList() })
    // 页面开始加载文件列表
    onMounted(() => { getFileList() })
    // 处理切换目录事件
    const categoryArrayChange = id => { searchParams.category_id = id }
    // 处理文件类型筛选
    const filterTypeChange = type => { searchParams.type = type }

    const fileChangeCategoryInterface = (file_ids, category_id) => {
      fileEditCateApi({ file_ids, category_id })
        .then(() => {
          getFileList()
          toast({
            component: ToastificationContent,
            props: { title: '移动文件到目录成功', icon: 'CheckCircleIcon', variant: 'success' },
          })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '移动文件到目录失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    const fileResetNameInterface = (file_id, file_name) => {
      fileEditNameApi({ file_id, file_name })
        .then(() => {
          getFileList()
          toast({
            component: ToastificationContent,
            props: { title: '更新文件名称成功', icon: 'CheckCircleIcon', variant: 'success' },
          })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '更新文件名称失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    /*
     * 目录相关属性
     * 增删改事件
     */
    // 目录对象基础信息
    const blankCategory = { id: 0, name: '', pid: 0 }
    // 根据目录对象生成vue响应式数据
    const category = ref(JSON.parse(JSON.stringify(blankCategory)))
    // 清空还原方法 控制分类编辑对话框
    const clearCategoryData = () => { category.value = JSON.parse(JSON.stringify(blankCategory)) }

    // 分类相关接口处理

    // 删除 分类目录
    const removeCategory = id => {
      $swal({
        title: '确定删除此文件目录吗?',
        text: '如果此目录中存在文件,文件将会移动至根目录!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          fileCategoryDeleteApi({ id })
            .then(() => {
              getFileCategory()
              searchParams.page = 1
              searchParams.category_id = 0
              toast({
                component: ToastificationContent,
                props: { title: '删除文件目录成功', icon: 'CheckCircleIcon', variant: 'success' },
              })
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: { title: '删除文件目录失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
              })
            })
        }
      })
    }
    // 新建 编辑 分类目录
    const updateCategory = data => {
      fileCategorySaveApi(data)
        .then(() => { getFileCategory() })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '添加文件目录失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }

    // 文件目录列表
    const categoryList = ref([{ value: 0, title: '根目录', pid: 0, html: '' }])
    // const categoryList = ref([])
    // 获取文件目录列表
    const getFileCategoryList = () => {
      fileCategorySelectApi()
        .then(res => {
          categoryList.value = res.data
          categoryList.value.unshift({ id: 0, html: '根目录', name: '根目录', pid: 0, })
        })
        .catch(err => {
          toast({
            component: ToastificationContent,
            props: { title: '获取文件目录列表失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
          })
        })
    }
    watch(isCategoryHandlerSidebarActive, val => { if (val) getFileCategoryList() })
    // 新建目录事件处理函数
    const createCategoryHandler = id => {
      isCategoryHandlerSidebarActive.value = true
      category.value.pid = id
    }
    // 修改目录事件处理函数
    const updateCategoryHandler = data => {
      // data.id = data.value
      isCategoryHandlerSidebarActive.value = true
      category.value = data
    }
    // 删除目录事件处理函数
    const deleteCategoryHandler = id => { removeCategory(id) }

    /* 文件操作接口 */
    const deleteFile = file_ids => {
      $swal({
        title: '确定删除文件吗?',
        text: '文件一旦被删除，将无法恢复!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          fileDeleteApi({ file_ids })
            .then(() => {
              selected.value = []
              getFileList()
              toast({
                component: ToastificationContent,
                props: { title: '删除文件成功', icon: 'CheckCircleIcon', variant: 'success' },
              })
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: { title: '删除文件失败', text: err.msg, icon: 'AlertTriangleIcon', variant: 'danger' },
              })
            })
        }
      })
    }

    /* 文件操作事件 */
    const shallFileChangeCategoryModal = ref(false)
    const shallFileResetNameModal = ref(false)

    // 定义文件右键菜单 dom 对象
    const fileMenu = ref(null)
    const fileObject = ref({})
    // 删除文件
    const deleteFileClick = () => {
      if (selected.value.length === 0) {
        return $swal({
          title: '操作错误',
          text: '您没有选择任何文件!',
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: '确定',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      } else {
        deleteFile(selected.value)
      }
    }

    // 批量移动文件
    // const moveFileClick = () => {
    //   if (selected.value.length === 0) {
    //     return $swal({
    //       title: '操作错误',
    //       text: '您没有选择任何文件!',
    //       icon: 'warning',
    //       showCancelButton: false,
    //       confirmButtonText: '确定',
    //       customClass: {
    //         confirmButton: 'btn btn-primary',
    //       },
    //       buttonsStyling: false,
    //     })
    //   }
    // }
    // 新建文件夹
    const createCategoryClick = () => {
      category.value.pid = searchParams.category_id
      isCategoryHandlerSidebarActive.value = true
    }

    const selectAllClick = () => {
      selected.value = []
      selected.value = distinctArrToPropArr(fileArray.value, 'id')
    }
    const cancelAllClick = () => { selected.value = [] }

    const fileMoveCategory = category_id => { fileChangeCategoryInterface([fileObject.value.id], category_id) }
    const fileResetName = newName => { fileResetNameInterface(fileObject.value.id, newName) }

    // 文件菜单点击事件
    const menuFileRightClick = value => {
      switch (value) {
        case 'view':
          window.open(fileObject.value.full_path, '_blank')
          break
        case 'move':
          getFileCategoryList()
          shallFileChangeCategoryModal.value = true
          break
        case 'edit':
          shallFileResetNameModal.value = true
          break
        case 'delete':
          deleteFile([fileObject.value.id])
          break
        case 'copy':
          $copyText(fileObject.value.path).then(() => {
            toast({
              component: ToastificationContent,
              props: { title: '文件地址复制成功', icon: 'BellIcon' },
            })
          }, () => {
            toast({
              component: ToastificationContent,
              props: { title: '文件地址复制失败', icon: 'BellIcon' },
            })
          })
          break
        case 'down': console.log(11111, 'down')
          break
        default: console.log(11111, 'default')
      }
    }

    // 文件右键菜单事件
    const fileMenuOpen = (event, file) => {
      fileMenu.value.open(event, file)
      fileObject.value = file
    }

    /* 文件上传类 配置项 */

    // 导入配置项参数
    const {
      header, fileUrl, uploadData, format, accept, maxSize, isMultiple,
      handleSuccess, handleFormatError, handleMaxSize, handleBeforeUpload,
    } = fileUpload(getFileList)

    // 设置渲染的DOM实例名，用于获取组件事件
    const refInputUpload = ref(null)

    // 处理不同类型文件对应的可上传类型及后缀格式名
    const formatFile = ref([])
    const acceptFile = ref('')

    // 处理DOM组件点击事件 触发文件上传组件获取文件信息，同时处理控制可上传文件类型等信息
    const upLoadClick = value => {
      formatFile.value = format.value[value]
      acceptFile.value = accept.value[value]
      uploadData.value.category_id = searchParams.category_id
      // 等待DOM刷新后触发事件
      nextTick(() => { refInputUpload.value.handleClick() })
    }

    /* 图片加载失败 */
    const onHandleImgUrlError = (ev, file) => {
      // console.log("🚀 ~ file: index.vue ~ line 654 ~ setup ~ ev", ev)
      ev.target.src = require('@/assets/file-svg/'+ fileTypeName(file.name))
    }

    return {
      routeParams,
      tags,
      perfectScrollbarSettings,
      isCategoryHandlerSidebarActive,
      // Left Sidebar Responsive
      mqShallShowLeftSidebar,
      total,
      selected,
      uploadLabel,
      fileSize,
      fileTypeName,

      // 文件上传
      refInputUpload,
      formatFile,
      acceptFile,
      upLoadClick,

      header,
      fileUrl,
      uploadData,
      format,
      accept,
      maxSize,
      isMultiple,

      handleSuccess,
      handleFormatError,
      handleMaxSize,
      handleBeforeUpload,

      // 目录新增
      categoryArrayChange,
      fileArray,
      fileCategory,
      getFileCategory,

      searchParams,
      filterTypeChange,

      // 目录操作
      category,
      categoryList,
      clearCategoryData,
      removeCategory,
      updateCategory,

      // 左侧目录分类事件
      createCategoryHandler,
      updateCategoryHandler,
      deleteCategoryHandler,

      // 文件操作事件
      deleteFileClick,
      createCategoryClick,
      fileMoveCategory,

      fileMenuList,
      menuFileRightClick,
      fileMenuOpen,
      fileMenu,
      shallFileChangeCategoryModal,
      shallFileResetNameModal,
      fileResetName,
      selectAllClick,
      cancelAllClick,

      /* 图片加载失败 */
      onHandleImgUrlError,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-file-manager-new.scss";
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import '@core/scss/vue/libs/vue-context.scss';


</style>
