<template>
  <b-modal
    id="modal-login-2"
    :visible="shallShowModal"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    footer-class="d-flex justify-content-between"
    centered
    @change="(val) => $emit('update:shall-show-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 文件移动目录 </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="$emit('update:shall-show-modal', false)" />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div>
        <b-button
          variant="outline-secondary"
          class="cursor-pointer"
          @click="$emit('update:shall-show-modal', false)"
        >
          取消
        </b-button>
      </div>

      <div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="onSumbit"
        >
          确定
        </b-button>
      </div>
    </template>
    <validation-observer ref="refFormObserver">
      <b-form>
        <b-form-group label="文件名称" label-for="fileName">
          <validation-provider name="文件名称" #default="{ errors }" rules="required">
            <b-form-input
              id="fileName" :state="errors.length > 0 ? false:null"
              v-model="fileName" trim placeholder="文件名称"
            />
            <b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'shallShowModal',
    event: 'update:shall-show-modal',
  },
  props: {
    shallShowModal: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* 必要组件加载区域 */

    // 目标目录id
    const fileName = ref(null)
    // 表单dom对象
    const refFormObserver = ref(null)
    // 提交事件处理
    const onSumbit = () => {
      refFormObserver.value.validate().then(success => {
        if (success) {
          emit('file-reset-name', fileName.value)
          emit('update:shall-show-modal', false)
        }
      })
    }
    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */
    watch(() => props.shallShowModal, val => { if (val) fileName.value = null })

    return {
      fileName,
      required,
      refFormObserver,
      onSumbit,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
