export default [
  'csv.svg',
  'db.svg',
  'dbf.svg',
  'div.svg',
  'dll.svg',
  'doc.svg',
  'docx.svg',
  'dot.svg',
  'eps.svg',
  'exe.svg',
  'flv.svg',
  'gif.svg',
  'h.svg',
  'htm.svg',
  'html.svg',
  'java.svg',
  'jpeg.svg',
  'jpg.svg',
  'js.svg',
  'json.svg',
  'jsp.svg',
  'lib.svg',
  'm4v.svg',
  'mdb.svg',
  'mdf.svg',
  'mkv.svg',
  'mov.svg',
  'mp3.svg',
  'mp4.svg',
  'mpeg.svg',
  'pdf.svg',
  'php.svg',
  'png.svg',
  'ppt.svg',
  'pptx.svg',
  'psd.svg',
  'py.svg',
  'rar.svg',
  'raw.svg',
  'rm.svg',
  'rmvb.svg',
  'rtf.svg',
  'svg.svg',
  'tiff.svg',
  'tmp.svg',
  'txt.svg',
  'vob.svg',
  'wav.svg',
  'wdb.svg',
  'weizhiwenjian.svg',
  'wma.svg',
  'wmv.svg',
  'wps.svg',
  'xd.svg',
  'xls.svg',
  'xlsx.svg',
  'xml.svg',
  'zip.svg',
  'a-3gp.svg',
  'a-7z.svg',
  'aac.svg',
  'aep.svg',
  'aepx.svg',
  'aet.svg',
  'aex.svg',
  'ai.svg',
  'asp.svg',
  'aspx.svg',
  'avi.svg',
  'bak.svg',
  'bat.svg',
  'bmp.svg',
  'c.svg',
  'cmd.svg',
  'cpp.svg',
  'cs.svg',
  'css.svg',
]
