import { ref, watch } from '@vue/composition-api'
// import store from '@/store'

export default function useCategoryHandler(props, emit) {
  // ------------------------------------------------
  // categoryLocal
  // ------------------------------------------------
  const categoryLocal = ref(JSON.parse(JSON.stringify(props.category.value)))
  const resetCategoryLocal = () => {
    categoryLocal.value = JSON.parse(JSON.stringify(props.category.value))
  }
  watch(props.category, () => { resetCategoryLocal() })
  watch(props.isCategoryHandlerSidebarActive, val => {
    if (val) resetCategoryLocal()
  })

  const onSubmit = () => {
    const categoryData = JSON.parse(JSON.stringify(categoryLocal))
    emit('update-category', categoryData.value)

    // Close sidebar
    emit('update:is-category-handler-sidebar-active', false)
  }

  return {
    categoryLocal,
    resetCategoryLocal,

    onSubmit,
  }
}
