<template>
  <b-modal
    id="modal-login-2"
    :visible="shallShowModal"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    footer-class="d-flex justify-content-between"
    centered
    @change="(val) => $emit('update:shall-show-modal', val)"
  >
    <!-- Modal Header -->
    <template #modal-header>
      <h5 class="modal-title"> 文件移动目录 </h5>
      <div class="modal-actions">
        <feather-icon icon="XIcon" class="ml-1 cursor-pointer" @click="$emit('update:shall-show-modal', false)" />
      </div>
    </template>

    <!-- Modal Footer -->
    <template #modal-footer>
      <div>
        <b-button
          variant="outline-secondary"
          class="cursor-pointer"
          @click="$emit('update:shall-show-modal', false)"
        >
          取消
        </b-button>
      </div>

      <div>
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          @click="onSumbit"
        >
          确定
        </b-button>
      </div>
    </template>
    <validation-observer ref="refFormObserver">
      <b-form>
        <b-form-group label="移动到.." label-for="category_id">
          <validation-provider name="目标文件目录" #default="{ errors }" rules="required">
            <v-select
              v-model="category_id"
              :state="errors.length > 0 ? false:null"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="categoryList" :clearable="false" label="html"
              :reduce="val => val.id"
              input-id="category_id"
            >
              <!-- <template v-slot:option="option">{{ option.html + option.title }}</template> -->
              <template v-slot:no-options>没有找到相关信息</template>
            </v-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { ref, watch } from '@vue/composition-api'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  directives: {
    Ripple,
  },
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'shallShowModal',
    event: 'update:shall-show-modal',
  },
  props: {
    shallShowModal: {
      type: Boolean,
      required: true,
    },
    categoryList: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    /* 必要组件加载区域 */

    // 目标目录id
    const category_id = ref(null)
    // 表单dom对象
    const refFormObserver = ref(null)
    // 提交事件处理
    const onSumbit = () => {
      refFormObserver.value.validate().then(success => {
        if (success) {
          emit('file-move-category', category_id.value)
          emit('update:shall-show-modal', false)
        }
      })
    }
    /* 组件页面数据准备区域，包括表单，下拉选择项，数据整理操作等 */
    watch(() => props.shallShowModal, val => { if (val) category_id.value = null })

    return {
      category_id,
      required,
      refFormObserver,
      onSumbit,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
